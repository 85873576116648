import { useEffect } from 'react';
import useLocalStorage from './useLocaleStorage';
function useTheme() {
    const [enabledTheme, setEnabledTheme] = useLocalStorage('theme', 'dark');
    const theme = typeof enabledTheme !== 'undefined' ? enabledTheme : 'dark';
    useEffect(()=>{
        const element = document.documentElement;
        if (theme == 'dark') {
            element.setAttribute('theme', 'dark');
        } else {
            element.setAttribute('theme', 'light');
        }
    }, [
        theme
    ]);
    return [
        theme,
        setEnabledTheme
    ];
}
export default useTheme;
