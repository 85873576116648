import { useEffect } from 'react';
import useLocalStorage from './useLocaleStorage';
import rtlDetect from 'rtl-detect';
function useLocale(pageLocale) {
    const initialLocale = pageLocale ? pageLocale : 'en';
    const [locale, setLocale] = useLocalStorage('locale', initialLocale);
    if (pageLocale && pageLocale !== locale) {
        setLocale(pageLocale);
    }
    useEffect(()=>{
        const langDir = rtlDetect.getLangDir(locale);
        document.documentElement.dir = langDir;
        document.documentElement.lang = locale;
        return ()=>{};
    }, [
        locale
    ]);
    return [
        locale,
        setLocale
    ];
}
export default useLocale;
