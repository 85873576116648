import '@formatjs/intl-relativetimeformat/locale-data/fa';
import '@formatjs/intl-relativetimeformat/locale-data/ar';
import '@formatjs/intl-relativetimeformat/locale-data/en';
const locales = {
    en: {
        id: 'en',
        default: true,
        path: '',
        direction: 'ltr',
        title: 'English',
        emoji: '🇬🇧',
        formatOptions: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            calendar: 'gregory'
        },
        dateFormat: 'DD/MM/YYYY',
        regions: [
            `en-US`,
            'en_GB'
        ]
    }
};
export { locales };
