export default {
    news: {
        title: 'News',
        desc: 'Cinematic VFX'
    },
    careers: {
        title: 'Careers',
        desc: 'Cinematic VFX'
    },
    interview: {
        title: 'Interview',
        desc: 'Cinematic VFX'
    },
    film: {
        title: 'Films',
        desc: 'Cinematic VFX'
    },
    tv: {
        title: 'TV',
        desc: 'Our VFX work in TV series'
    },
    short: {
        title: 'Short',
        desc: 'Short animation and episodic VFX'
    },
    commercial: {
        title: 'Advertising',
        desc: 'Our VFX work in commercial and advertising'
    }
};
