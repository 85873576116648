import * as React from 'react';
import Context from './src/context/Context';
import { getMessages } from './src/_i18n/messages';

export const wrapPageElement = ({ element, props }) => {
  const { locale } = props.pageContext;

  if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
  }

  return (
    <Context pageLocale={locale} messages={getMessages(locale)} {...props}>
      {element}
    </Context>
  );
};

const transitionDelay = 20;

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  // To fix offcanvas scroll-to-top bug
  if (location.hash) {
    return false;
  }

  //For transitions
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay);
  }
  return false;
};
