const themes = {
    names: [
        'Default',
        'Carpet'
    ],
    modes: [
        'light',
        'dark'
    ],
    locales: [
        'en',
        'fa'
    ]
};
export { themes };
